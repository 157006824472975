* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto Slab', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
  overflow: hidden;
}

*::-webkit-scrollbar {
  display: none;
}

@keyframes spinner {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
